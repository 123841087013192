import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { Container, Typography, Box, Button, TextField, MenuItem } from "@mui/material";
import logo from "../assets/logo.png";
import SendIcon from '@mui/icons-material/Send';
import * as React from 'react';
import playstoreIcon from "../assets/google_play.png";
import appstoreIcon from "../assets/app_store.png";
import Api from "../Api";
const PLAYSTORE = 'https://play.google.com/store/apps/details?id=events.litmaps';
const APPSTORE = 'https://apps.apple.com/ca/app/litmaps-events-experiences/id1582858341';
const buttonStyle = {
  background: '#000000',
};

const buttonSentStyle = {
  background: '#EFEFEF',
};

interface Country { name: string, code: string };

const countries: Country[] = [
  { name: "CA", code: "+1" },
  { name: "US", code: "+1" },
];

function Home() {
  const [country, setCountry] = React.useState(countries[0].name + " " + countries[0].code);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [sentLink, setSentLink] = React.useState(false);

  const handleCountryCodeChange = (event: any) => {
    setCountry(event.target.value);
  };

  const handlePhoneNumberChange = (event: any) => {
    setPhoneNumber(event.target.value);
    setSentLink(false);
  };

  const sendLink = async () => {
    if (sentLink) return console.log("already sent link to this number");
    try {
      await Api.invite.sendDownloadLink(phoneNumber);
      setSentLink(true);
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <Nav />
      <Container maxWidth="sm">

        {/* Top Paragraph */}
        <Typography variant="h6" paddingTop={"100px"}>
          Find fun things to do near you, and friends who want to hang out with you in real life.
        </Typography>

        {/* Logo and call to action title */}
        <Box display="flex" flexDirection="row" alignContent="center" alignItems="center" paddingTop={"50px"}>
          <img height={120} src={logo} alt="litmaps icon" />
          <Typography variant="h2" fontWeight={600}>
            Get Litmaps.
          </Typography>
        </Box>

        {/* Phone number textfield */}
        <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
          <Box padding={"10px"}>

            {/* Country code selection */}
            <TextField
              select
              id="country-code"
              variant="outlined"
              size="small"
              value={country}
              onChange={handleCountryCodeChange}
            >
              {countries.map((country) => (
                <MenuItem key={country.name} value={country.name + " " + country.code}>
                  {country.name + " " + country.code}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {/* Phone Number Text Field */}
          <Box padding={"10px"}>
            <TextField id="phone-number" label="Phone Number" variant="outlined" size="small" onChange={handlePhoneNumberChange} />
          </Box>

          <Button
            variant="contained"
            style={sentLink ? buttonSentStyle : buttonStyle}
            size="large"
            endIcon={!sentLink && <SendIcon />}
            onClick={sendLink}
            disabled={sentLink}>
            {sentLink ? "Sent" : "Send"}
          </Button>
        </Box>

        <Box paddingTop={"70px"}>
          <a href={PLAYSTORE}> <img src={playstoreIcon} height={60} alt="play store" /></a>
          <a href={APPSTORE}> <img src={appstoreIcon} height={60} alt="app store" /></a>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Home;