import Nav from "./components/Nav";
import { Container } from "@mui/material";

function PrivacyPolicyPage() {

  return (
    <div>
      <Nav />
      <Container>
        <h1>Privacy Policy</h1>
        <p>
          This Service is provided at no cost and is intended for use as is.
        </p>

        <p>
          For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your phone number. We do not sell any of your information. The information that we request will be retained by us and used as described in this Privacy Policy.
        </p>
        <p>
          When sharing events, this Service will request access to the user's contacts. This is for sharing the invite link directly to the user's contacts, and the user's contacts are not stored, nor is this information shared with any other users, however, the contacts are also sent to the server to find friends who also have the App. This information is not stored. If you choose to decline to share this permission, you may not be able to use some portions of this Service.
        </p>
        <p>
          Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. We use this to store login data. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
        </p>
      </Container>
    </div>
  );
}

export default PrivacyPolicyPage;