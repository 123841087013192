import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import InvitePage from './pages/InvitePage';
import LiteventPage from './pages/LiteventPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import SuggestedEventPage from './pages/SuggestedEventPage';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#E33E7F'
    }
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/suggested-event/:id" element={<SuggestedEventPage />} />
          <Route path="/litevent/:id" element={<LiteventPage />} />
          <Route path="/privacy/" element={<PrivacyPolicyPage />} />
          <Route path="/invite/:id" element={<InvitePage />} />
        </Routes>
      </BrowserRouter>
    </ MuiThemeProvider>
  );
}

export default App;