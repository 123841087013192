import { Link } from 'react-router-dom';
import { Toolbar, Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box
      width={"100vw"}
      height={"40px"}
      style={{
        marginTop: "calc(50px)",
        bottom: "0",
      }}>
      <Toolbar>
        <Typography paddingRight={"10px"}><Link to="/privacy/">Privacy Policy</Link></Typography>
      </Toolbar>
    </Box>
  );
}

export default Footer;