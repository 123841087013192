import { useParams } from 'react-router-dom';
import React from "react";
import { Litevent } from '../interfaces';
import Api from '../Api';
import Nav from './components/Nav';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { AccessTime, Whatshot, PhotoAlbumRounded } from '@material-ui/icons';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createMuiTheme } from '@mui/material/styles/createTheme';
import { Button, TextField } from '@mui/material';

const navTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff5722'
    },
    text: {
      secondary: '#555555'
    }
  }
})
function LiteventPage() {
  const { id } = useParams();
  const [litevent, setLitevent] = React.useState<Litevent | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [pageIndex, setPageIndex] = React.useState(0);
  const onPageChanged = (event: any, newValue: any) => {
    setPageIndex(newValue);
  }
  React.useEffect(() => {
    async function _fetch() {
      try {
        const _litevent = await Api.litevent.getById(id!);
        setLitevent(_litevent);
        console.log(_litevent);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    _fetch();
  }, [id]);


  if (loading) return (
    <div>
      <Nav />
      Loading ...
    </div>
  );

  if (!litevent) return (
    <div>
      <Nav />
      No event found
    </div>
  );


  return (
    <Box minHeight={"100vh"}>
      <Nav />

      <Typography align="center" style={{ fontWeight: "bold", marginTop: "20px", marginBottom: "15px" }}>{litevent.name}</Typography>
      <Divider />
      {/* <p>Event Owner: {litevent.eventOwnerName}</p> */}
      {(pageIndex === 0) ?
        <DetailsPage litevent={litevent} /> :
        <PhotosPage litevent={litevent} />
      }
      <BottomNav litevent={litevent} onChange={onPageChanged} pageIndex={pageIndex} />
    </Box >
  );
}

const GetInviteBox: React.FC<{ litevent: Litevent }> = ({ litevent }) => {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [sentLink, setSentLink] = React.useState(false);

  const handlePhoneNumberChange = (event: any) => {
    setPhoneNumber(event.target.value);
    setSentLink(false);
  };

  const getInviteFromSharedEventLink = async () => {
    if (sentLink) return console.log("already sent link to this number");
    try {
      await Api.invite.getInviteFromSharedEventLink(phoneNumber, litevent);
      setSentLink(true);
    }
    catch (error) {
      console.log(error);
    }
  }

  const buttonStyle = {
    background: '#FF5722',
  };

  const buttonSentStyle = {
    background: '#EFEFEF',
  };

  return (
    <Box
      width={"100vw"}
      height={"70px"}
      bgcolor={"white"}
      display="flex"
      flexDirection="row"
      alignContent="center"
      alignItems="center"
    >
      <Box padding={"10px"} flexGrow={1}>
        <TextField fullWidth={true} id="phone-number" label="Phone Number" variant="outlined" size="small" onChange={handlePhoneNumberChange} />
      </Box>

      <Box padding={"10px"}>
        <Button
          variant="contained"
          style={sentLink ? buttonSentStyle : buttonStyle}
          size="large"
          onClick={getInviteFromSharedEventLink}
          disabled={sentLink}>
          {!sentLink ? "Get Invite" : "Invited"}
        </Button>
      </Box>
    </Box>
  );
}

const BottomNav: React.FC<{ litevent: Litevent, pageIndex: Number, onChange: any }> = ({ litevent, pageIndex, onChange }) => {
  return (
    <Box style={{ position: "fixed", bottom: "0px", width: "100%" }}>

      <Divider />

      <GetInviteBox litevent={litevent} />
      <Divider />
      <Box style={{ backgroundColor: "white" }} padding={"10px"}>
        <ThemeProvider theme={navTheme}>
          <BottomNavigation
            showLabels
            value={pageIndex}
            onChange={onChange}
          >
            <BottomNavigationAction label="Details" icon={<Whatshot />} />
            <BottomNavigationAction label="Photos" icon={<PhotoAlbumRounded />} />
          </BottomNavigation>
        </ThemeProvider>
      </Box>
      {/* <Box style={{ height: "10px", backgroundColor: "white" }} /> */}
    </Box>
  )
}

const DetailsPage: React.FC<{ litevent: Litevent }> = ({ litevent }) => {
  return (<Box>
    <LitEventLocation litevent={litevent} />

    <Box display="flex" flexDirection="row" alignItems="center" alignContent="center" padding="10px">
      <Box padding="10px">
        <AccessTime style={{ color: "gray" }} />
      </Box>

      <Box padding="10px">
        <Typography variant="h6">{new Date(litevent.startDate).toLocaleString()}</Typography>
      </Box>
    </Box>
  </Box>);
}

const PhotosPage: React.FC<{ litevent: Litevent }> = ({ litevent }) => {
  return (<Box>
    {
      litevent.imageLinks.length > 0 ?
        litevent.imageLinks.map(imageLink => (
          <img style={{ aspectRatio: "1", width: "100%", objectFit: "cover" }} src={imageLink} alt="litevent pic" />)) : <div />
    }
  </Box>);
}

const LitEventLocation: React.FC<{ litevent: Litevent }> = ({ litevent }) => {
  return (
    <Box padding={"0px"}>
      <Typography variant="h5" >Location</Typography>
      <Typography >{litevent.address}</Typography>
      <Box bgcolor="black" width={"100%"} height={"200px"}>
        <iframe title="map" style={{ height: "200px", width: "100%" }}
          src={`https://jakebildy.github.io/litmaps_map/?lat=${litevent.latitude}&lng=${litevent.longitude}`} />
      </Box>
    </Box>
  );
}

export default LiteventPage;