import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, Button } from '@mui/material';
import Link2 from '@mui/material/Link';
import Logo from "../../assets/nav-logo.png";

const appbarStyle = {

  background: '#FFFFFF',
};

function Nav() {
  return (
    <AppBar position="sticky" style={appbarStyle} elevation={1}>
      <Toolbar>
        <Link to="/"><Box component="img" sx={{ height: 50 }} alt="Litmaps" src={Logo} /></Link>
        <Box flexGrow={1} />
        {/* <Typography paddingRight={"10px"} paddingLeft={"10px"}><Link to="/suggested-event/6192f8015315e9000a5c0619">Suggested Event</Link></Typography> */}
        {/* <Typography paddingRight={"10px"}><Link to="/litevent/6192ea8e8ef197000a947cf6">Litevent</Link></Typography> */}
        {/* <Typography paddingRight={"10px"}><Link to="/privacy/">Privacy Policy</Link></Typography> */}
        <Link2 href="/download" underline="none">
          <Button
            variant="contained"
            style={{
              border: '1px solid',
              borderColor: '#E4E4E4',
              background: '#ffffff',
              borderRadius: '200px',
              textTransform: 'none'
            }}
          > <Typography style={{ color: '#51525A', fontWeight: "bold" }}>Get app</Typography>
          </Button>
        </Link2>
      </Toolbar>
    </AppBar>
  );
}

export default Nav;