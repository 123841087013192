// import { useParams } from 'react-router-dom';
// import React from "react";

const PLAYSTORE = 'https://play.google.com/store/apps/details?id=events.litmaps';
const APPSTORE = 'https://apps.apple.com/ca/app/litmaps-events-experiences/id1582858341';
const LITMAPS_SITE = 'https://litmaps.events';

//6120e25519657fef985b8a66
function InvitePage() {
  // const { id } = useParams();
  /**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
  function redirectToDownloadApp() {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      window.location.href = PLAYSTORE;
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href = APPSTORE;
      return "iOS";
    }
    
    window.location.href = LITMAPS_SITE;
    return "unknown";
  }

  redirectToDownloadApp();

  return (
    <div>
      Loading...
    </div>
  );
}

export default InvitePage;