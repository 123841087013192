import { Litevent, SuggestedEvent } from './interfaces';
import axios from "axios";
const remoteURL = "https://noted-processor-321404.uc.r.appspot.com/api";
const testingURL = "http://localhost:9080/api";
const TESTING = false;
const baseURL = TESTING ? testingURL : remoteURL;

const Api = {
  litevent: {
    getById: async (id: String) : Promise<Litevent> => {
      const response = await axios.get(baseURL + "/litevent/litevents/id/" + id);
      return response.data;
    }
  },

  suggestedEvent: {
    getById: async (id: String) : Promise<SuggestedEvent>  => {
      const response = await axios.get(baseURL + "/suggested-event/id/" + id);
      return response.data;
    }
  },

  invite: {
    getById: async (id: String) : Promise<SuggestedEvent>  => {
      const response = await axios.get(baseURL + "/invite/invites/" + id);
      return response.data;
    },
    sendDownloadLink: async (phoneNumber: String) : Promise<SuggestedEvent>  => {
      const response = await axios.post(baseURL + "/invite/text-download-link/" + phoneNumber);
      return response.data;
    },
    getInviteFromSharedEventLink: async (phoneNumber: String, litevent: Litevent) : Promise<SuggestedEvent>  => {
      const response = await axios.post(baseURL + `/invite/shared-event/${litevent._id}/${phoneNumber}`);
      return response.data;
    },
  },
}

export default Api;