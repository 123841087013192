import { useParams } from 'react-router-dom';
import React from "react";
import { SuggestedEvent } from '../interfaces';
import Api from '../Api';
import Nav from './components/Nav';
import { Container, Box, Typography } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Divider from '@material-ui/core/Divider';
function SuggestedEventPage() {
  const { id } = useParams();
  const [suggestedEvent, setSuggestedEvent] = React.useState<SuggestedEvent | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function _fetch() {
      try {
        const _suggestedEvent = await Api.suggestedEvent.getById(id!);
        setSuggestedEvent(_suggestedEvent);
        console.log(_suggestedEvent);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    _fetch();
  }, [id]);

  if (loading) return (
    <div>
      <Nav />
      Loading ...
    </div>
  );

  if (!suggestedEvent) return (
    <div>
      <Nav />
      No suggested event found
    </div>
  );

  return (
    <div>
      <Nav />
      <Container>

        {/* Desktop */}
        <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
        >
          <SuggestedEventHeader suggestedEvent={suggestedEvent} />
          <Box display="flex" flexDirection="row">
            <SuggestedEventPics suggestedEvent={suggestedEvent} />
            <Box>
              <SuggestedEventLocation suggestedEvent={suggestedEvent} />
              <SuggestedEventOpeningHours suggestedEvent={suggestedEvent} />
            </Box>
          </Box>
        </Box>


        {/* Mobile */}
        <Box sx={{ display: { xs: 'block', sm: 'none', md: "none" } }}
        >
          <SuggestedEventHeader suggestedEvent={suggestedEvent} />
          <Divider />
          <SuggestedEventPics suggestedEvent={suggestedEvent} />
          <Box>
            <SuggestedEventLocation suggestedEvent={suggestedEvent} />
            <SuggestedEventOpeningHours suggestedEvent={suggestedEvent} />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

const SuggestedEventLocation: React.FC<{ suggestedEvent: SuggestedEvent }> = ({ suggestedEvent }) => {
  return (
    <Box padding={"0px"}>
      <Typography variant="h5" >Location</Typography>
      <Typography >{suggestedEvent.address}</Typography>
      <Box bgcolor="black" width={"100%"} height={"200px"}>
        <iframe title="map" style={{ height: "200px", width: "100%" }}
          src={`https://jakebildy.github.io/litmaps_map/?lat=${suggestedEvent.latitude}&lng=${suggestedEvent.longitude}`} />
      </Box>
    </Box>
  );
}

const SuggestedEventOpeningHours: React.FC<{ suggestedEvent: SuggestedEvent }> = ({ suggestedEvent }) => {
  return (
    <Box padding={"20px"}>
      <Box display="flex" flexDirection="row">
        <Typography variant="h5" >Opening Hours</Typography>
        <Box flexGrow={1} />
        <Typography >{suggestedEvent.openingHours.open_now ? "Open" : "Closed"}</Typography>
      </Box>

      {suggestedEvent.openingHours.weekday_text.map((text, index) => (
        <Typography key={index}>
          {text}
        </Typography>
      ))}

    </Box>
  );
}

const SuggestedEventHeader: React.FC<{ suggestedEvent: SuggestedEvent }> = ({ suggestedEvent }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" padding={"4px"}>
      <Typography variant="h4" paddingRight="10px">🔥</Typography>
      <Box>
        <Typography variant="h5">{suggestedEvent.category}</Typography>
        <Typography variant="h6">{suggestedEvent.name}</Typography>
      </Box>
    </Box>
  );
}

const SuggestedEventPics: React.FC<{ suggestedEvent: SuggestedEvent }> = ({ suggestedEvent }) => {

  return (
    <Box width={"100%"} padding={"0px"}>
      <Carousel
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosNewIcon />}
      >
        {suggestedEvent.imageLinks.map((link, index) => (
          <Box key={index} display="flex"
            justifyContent="center"
            alignItems="center">
            <img
              src={link}
              // width={500}
              height={400}
              alt={"suggested event"}
              loading="lazy"
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}

export default SuggestedEventPage;